import Botpoison from "@botpoison/browser";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // To capture the URL parameters
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import "./SignUpForm.css";

const botpoison = new Botpoison({
  publicKey: process.env.REACT_APP_BOTPOISON_ID,
});

// const brevoApiUrl = "https://api.brevo.com/v3/contacts";
// const brevoApiKey = process.env.REACT_APP_BREVO_API_KEY;

const isMobile = () =>
  window.matchMedia && window.matchMedia("(max-width: 480px)").matches;

const cacheLtr = createCache({
  key: "muiltr",
});

const cacheRtl = createCache({
  key: "muirtl",
  // prefixer is the only stylis plugin by default, so when
  // overriding the plugins you need to include it explicitly
  // if you want to retain the auto-prefixing behavior.
  stylisPlugins: [prefixer, rtlPlugin],
});
const ltrTheme = createTheme({ direction: "ltr" });
const rtlTheme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: "Assistant",
  },
});

const CustomTextField = styled(
  ({ focusBorderColor, borderColor, ...props }) => <TextField {...props} />
)(({ focusBorderColor, borderColor }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: borderColor,
    },
    "&:hover fieldset": {
      borderColor: borderColor,
    },
    "&.Mui-focused fieldset": {
      borderColor: focusBorderColor,
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: focusBorderColor,
    fontWeight: "900",
  },
  "& .MuiFormHelperText-root": {
    // error fields validation
    position: "absolute",
    marginLeft: "3px",
    textAlign: "start",
    fontWeight: "900",
    minWidth: "250px",
    top: "100%", // Position below the input field
    left: "0",
    transform: isMobile() ? "translateY(0px)" : "translateY(2px)",
    // fontFamily: "Assistant",
  },

  marginBottom: isMobile() ? "8px" : "16px",
  marginRight: isMobile() ? "0" : "10px",
  marginLeft: isMobile() ? "0" : "10px",
  // "& .MuiInputBase-input:lang(he)": {
  //   fontWeight: "900",
  // },
}));

const SignUpForm = (props) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({ email: "", city: "" });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [isRtl, setIsRtl] = React.useState(false);
  const [utmParams, setUtmParams] = useState({});
  // const [simulateError, setSimulateError] = useState(false);
  // const [isAgreed, setIsAgreed] = useState(false);

  // Update RTL state based on the current language direction
  useEffect(() => {
    const direction = i18n.dir();
    setIsRtl(direction === "rtl");
    console.log(`Current direction: ${direction}`);
  }, [i18n, i18n.language]);

  // React.useLayoutEffect(() => {
  //   document.body.setAttribute("dir", isRtl ? "rtl" : "ltr");
  // }, [isRtl]);

  // Capture UTM parameters from the URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(searchParams.entries());
    setUtmParams(params); // Store UTM parameters
  }, [location]);
  // console.log("UTM parameters:", utmParams);

  const validate = () => {
    const errors = {};
    if (!formData.email) {
      errors.email = t("customers_validation.required");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = t("customers_validation.invalid_email");
    }

    if (!formData.city) {
      errors.city = t("customers_validation.city_required");
    } else if (formData.city.length < 2 || formData.city.length > 17) {
      errors.city = t("customers_validation.city_min");
    } else if (!/^([a-z\u0590-\u05fe ]*)+$/i.test(formData.city)) {
      errors.city = t("customers_validation.city_letters_only");
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    setSubmitting(true);
    // const { solution } = await botpoison.challenge();

    let solution;
    let toastId;

    // Botpoison challenge
    try {
      toastId = toast.loading(t("toast_message.verifying_humanity"));
      solution = await Promise.race([
        botpoison.challenge(),
        new Promise((_, reject) =>
          setTimeout(
            () => reject(new Error("Botpoison challenge timed out")),
            10000
          )
        ),
      ]);
    } catch (error) {
      console.error("Botpoison challenge failed:", error);
      toast.update(toastId, {
        render: t("toast_message.verification_failed"),
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      setSubmitting(false);
      return;
    }

    try {
      // const solution = await botpoison.challenge();
      // console.log("Botpoison challenge solved:", solution);
      toast.update(toastId, {
        render: t("toast_message.send"),
        type: "loading",
        isLoading: true,
      });

      const currentLanguage = i18n.language;

      const payload = {
        email: formData.email,
        city: formData.city,
        language: currentLanguage,
        botpoisonSolution: solution.solution, // Send the solution to the server
        utmParams, // Include captured UTM parameters
      };
      // Call Netlify function

      // const payload = {
      //   email: formData.email,
      //   attributes: {
      //     CITY: formData.city,
      //     LANGUAGE: currentLanguage,
      //   },
      //   emailBlacklisted: false,
      //   smsBlacklisted: false,
      //   listIds: [6],
      //   updateEnabled: false,
      //   _botpoison: solution.solution,
      // };
      // if (simulateError) {
      //   // Simulate an error
      //   throw new Error("Simulated error");
      // }
      // await axios.post(brevoApiUrl, payload, {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Accept: "application/json",
      //     "api-key": brevoApiKey,
      //   },
      // });
      const response = await axios.post(
        "/.netlify/functions/submitForm",
        payload
      );
      // Use the response from the serverless function
      if (response.data.success) {
        toast.update(toastId, {
          render: t("toast_message.thanks"),
          type: "success",
          isLoading: false,
          autoClose: 8000,
          // autoClose: false,
          closeOnClick: true,
          draggable: true,
          closeButton: true,
          icon: "🚀",
        });

        // Optionally handle additional data returned by the function
        console.log("Serverless function response:", response.data);

        // Reset the form
        setFormData({ email: "", city: "" });
      } else if (response.data.error === "Email already registered") {
        toast.update(toastId, {
          render: t("toast_message.email_already_registered"), // Add this message to `translation.json`
          type: "info",
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        throw new Error(response.data.error || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Form submission failed:", error);
      toast.update(toastId, {
        render: t("toast_message.error"),
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setSubmitting(false);
    }
  };
  // const formStyles = {
  //   background: props.buttonColor,
  //   color: props.textColor,
  //   border: props.borderColor,
  //   error: props.colorr,
  //   btnColor: props.textColor,
  // };
  const inputContainerStyle = isMobile()
    ? {
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center horizontally on mobile
        width: "100%",
      }
    : null; // Fallback to default class-based styling on non-mobile
  return (
    <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
        {/* <button type="button" onClick={() => setSimulateError(!simulateError)}>
          Toggle Error Simulation
        </button> */}
        <form
          className="NotifyForm"
          id="resettable"
          onSubmit={handleSubmit}
          noValidate
        >
          <div
            className="input-container"
            style={inputContainerStyle} // Apply styles only on mobile
          >
            <div className="form-group">
              <CustomTextField
                variant="outlined"
                margin="normal"
                label={t("hero_section.customer_email_field_title")}
                placeholder={t("hero_section.customer_email_placeholder")}
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                className={`email_input custom-width ${props.inputFocusClass}`}
                InputLabelProps={{
                  style: { color: props.textColor },
                }}
                InputProps={{
                  style: { color: props.textColor },
                }}
                FormHelperTextProps={{
                  style: { color: props.colorr },
                }}
                borderColor={props.borderColor}
                focusBorderColor={props.focusBorderColor}
              />
            </div>
            <div className="form-group">
              <CustomTextField
                variant="outlined"
                margin="normal"
                label={t("hero_section.customer_city_field_title")}
                placeholder={t("hero_section.customer_city_placeholder")}
                name="city"
                value={formData.city}
                onChange={handleChange}
                error={!!errors.city}
                helperText={errors.city}
                className={`city_input custom-width ${props.inputFocusClass}`}
                InputLabelProps={{
                  style: { color: props.textColor },
                }}
                InputProps={{
                  style: { color: props.textColor },
                }}
                FormHelperTextProps={{
                  style: { color: props.colorr },
                }}
                borderColor={props.borderColor}
                focusBorderColor={props.focusBorderColor}
              />
            </div>
          </div>

          {/* New checkbox for agreeing to Terms of Use and Privacy Policy */}
          {/* <div className="agreement-container">
            <label className="agreement-label">
              <input
                type="checkbox"
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                required
              />
              {t("hero_section.agree_to_terms")}
              <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">
                {t("hero_section.terms_of_use_link")}
              </a>
              {t("hero_section.and")}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("hero_section.privacy_policy_link")}
              </a>
            </label>
          </div> */}

          <div
            className="btn"
            style={
              isMobile()
                ? {
                    display: "flex",
                    justifyContent: "center", // Center only on mobile
                    width: "100%",
                    // marginTop: "20px",
                  }
                : undefined // No style changes for PC
            }
          >
            <button
              variant="contained"
              type="submit"
              disabled={submitting}
              // buttonColor={props.submitButtonColor}
              // submitButtonHoverColor={props.submitButtonHoverColor}
              className="submit_btn"
              style={{
                backgroundColor: props.submitButtonColor,
                color: props.buttonTextColor,
                margin: isMobile() ? "0 auto" : undefined,
              }}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor = props.submitButtonHoverColor)
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = props.submitButtonColor)
              }
            >
              {submitting
                ? t("hero_section.customer_email_btn_process")
                : t("hero_section.customer_email_btn")}
            </button>
          </div>
        </form>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default SignUpForm;
